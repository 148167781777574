body {
  background: #fafafa;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* ==========================================================================
outlet
========================================================================== */
#outlet-map {
  width: 100%;
  height: 70vh; }

/* ==========================================================================
visit audit
========================================================================== */
#supervision-detail {
  height: 300px;
  padding: 10px; }
  #supervision-detail .detail-pane img {
    width: auto;
    max-height: 280px; }

/* ==========================================================================
product category
========================================================================== */
table.tree-grid tr.tree-grid-row a {
  text-decoration: none; }

.scrollable-dropdown-menu .dropdown-menu {
  max-height: 150px;
  overflow-y: auto; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
;
.title-center {
	text-align: center;
	font-size: 30px;
	margin-bottom: 20px;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	color: inherit;
}

.table-center, .table-center>thead>tr>th {
	text-align: center;
}

.table>tbody>tr>td {
	vertical-align: middle;
}

.form-group.custom-margin {
	margin-bottom: 3px;
}

.input-sm {
	font-size: 14px;
}

.help-block {
	margin-bottom: 3px;
}

.navbar-right .dropdown-menu {
    left: 0;
    right: auto;
}

.custom-hr {
	margin-top: 5px;
	margin-bottom: 10px;
	border-top: 1px solid rgba(0,0,0,0.2);
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu>.dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	-webkit-border-radius: 0 6px 6px 6px;
	-moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
	display: block;
}

.dropdown-submenu>a:after {
	display: block;
	content: " ";
	float: right;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	border-left-color: #cccccc;
	margin-top: 5px;
	margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
	border-left-color: #ffffff;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
	left: -100%;
	margin-left: 10px;
	-webkit-border-radius: 6px 0 6px 6px;
	-moz-border-radius: 6px 0 6px 6px;
	border-radius: 6px 0 6px 6px;
}

.input-group {
	margin: 0px;
}

.input-group-btn .btn {
	height: 34px;
}
.input-group-btn .btn-sm {
	height: 30px;
}

.thead-float, .tbody-float, .tr-float, .td-float, .th-float {
	display: block;
}

.tr-float:after {
	content: ' ';
	display: block;
	visibility: hidden;
	clear: both;
}

.thead-float th {
	height: 30px;
}

.tbody-float {
	height: 250px;
	overflow-y: auto;
}

.tbody-float td, .thead-float th {
	height: 40px;
	width: 15%;
	float: left;
}

.radio, .checkbox {
    margin-top: 0px;
}

.popover {
	max-width: 868px;
	width: 868px;
	height: auto;
	padding: 1em;
}

.btn-calculator {
	width: 60px;
	height: 40px;
}

a {
    outline: none !important;
}

.uib-tab-content {
	padding: 15px;
	background: white;
	border: 1px solid #ddd;
	border-top: 0px;
	border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.disabled a {
  cursor: default !important; 
}

.row-tight {
	margin-left: -5px;
    margin-right: -5px;	
}

.row-tight>div {
	padding: 0 5px;
}

.dndPlaceholder {
	list-style-type: none;
}

.trash-box {
    color: #333;
    background-color: #fff;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    border: 1px solid #ccc;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.trash-box.dndDragover{
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; 
}

.trash-box li {
	display: none;
}

.md-editor>textarea {
	background-color: white;
}
.md-editor>.md-preview {
	padding: 10px;
}

.thumbnail.active {
	background-color: #d9edf7;
}

.custom-grid {
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2px 5px;
}

.custom-grid table {
	margin-bottom: 0;	
}

.custom-grid .row {
	margin-left: 0; 
	margin-right: 0;
    border-bottom: 1px solid #ddd;
}

.custom-grid .row div {
    padding: 5px 0;
    vertical-align: top;
};
.dndList ul[dnd-list],
.dndList ul[dnd-list] > li {
	position: relative;
}

.dndList ul[dnd-list] {
    min-height: 42px;
    padding-left: 0px;
}

.dndList ul[dnd-list] .dndDraggingSource {
    display: none;
}

.dndList ul[dnd-list] .dndPlaceholder {
    display: block;
    background-color: #ddd;
    min-height: 42px;
}

.dndList ul[dnd-list] li {
    background-color: #fff;
    border: 1px solid #ddd;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
}

.formulaWidget .dropzone ul[dnd-list] {
    min-height: 42px;
    margin: 0px;
    padding-left: 0px;
}

.formulaWidget .dropzone li {
    display: block;
}

.formulaWidget .dropzone .dndDragging {
    opacity: 0.7;
}

.formulaWidget .dropzone .dndDraggingSource {
    display: none;
}

.formulaWidget .dropzone .dndPlaceholder {
    background-color: #ddd !important;
    min-height: 42px;
    display: block;
    position: relative;
}

.formulaWidget .dropzone .itemlist {
    min-height: 125px !important;
}

.formulaWidget .dropzone .itemlist > li {
    color: #585858;
    float: left;
    font-weight: 700;
    font-size: 16px;
    margin: 5px;
    text-align: center;
}

.formulaWidget .dropzone .itemlist > li#exponent { 
    float: left; 
    font-size: 14px; 
    border: 1px solid #a1a1a1;
    padding: 0 5px;
    border-radius: 10px;
}

.formulaWidget. dropzone .container-element {
    margin: 10px;
} 
;